import { Column } from 'react-table';
import { Link, useParams } from 'react-router-dom';
import { FaFile, FaFlagCheckered, FaWarehouse } from 'react-icons/fa';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import Card from 'components/molecules/Card';
import TextDeco from 'components/atoms/Text';
import Button from 'components/atoms/Buttons';
import Select from 'components/molecules/Inputs/Select';
import Table from 'components/organisms/Table/ReactTable';
import Notification from 'components/atoms/Notifications';
import LocationCell from 'components/atoms/LocationCell/index';

import API from 'constants/API';
import Routes from 'constants/Routes';
import { GET, POST } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import { DocumentsBuilder, FormatDocument, DocumentHistories, PackagesHistories } from 'models/DocumentBuilder';
import useOptions from 'utils/Hook/GetOptions';

interface Options {
    label: string;
    value: string;
}
const etatDocLivraisonOK = 11;
const etatColiKO = [4, 8, 12];

type RenvoisColisData = {
    fk_tiersSource: string;
    fk_tiersDestination: string;
    numero: string;
    colis: { numero: string; poids: string; id_colis: number }[];
};

const Document = () => {
    const { id = '' } = useParams<{ id: string }>();
    const { state, dispatch } = useContext(NotificationContext);
    const { statusOptions: statusOption } = useOptions({ getStatus: 'document' });
    const [document, setDocument] = useState(DocumentsBuilder());
    const [showSelect, setShowSelect] = useState<boolean>(false);
    const [status, setStatus] = useState<Options>({ label: '', value: '' });
    const [renvoisColisData, setRenvoisColisData] = useState<RenvoisColisData>();

    const GetDocument = async () => {
        const response = await GET(API.DOCUMENT + id);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        if (response.data[0].documentHistory[0].fk_etat === etatDocLivraisonOK) {
            let data;
            for (let index = 0; index < response.data[0].colisHistory.length; index++) {
                if (etatColiKO.includes(response.data[0].colisHistory[index].fk_etat))
                    if (!data) {
                        data = {
                            fk_tiersSource: response.data[0].fk_tiersSource,
                            fk_tiersDestination: response.data[0].fk_tiersDestination,
                            numero: `${response.data[0].numero_document}*`,
                            colis: [
                                {
                                    numero: response.data[0].colisHistory[index].numero_colis,
                                    poids: response.data[0].colisHistory[index].poids,
                                    id_colis: response.data[0].colisHistory[index].id_colis,
                                },
                            ],
                        };
                    } else {
                        data.colis.push({
                            numero: response.data[0].colisHistory[index].numero_colis,
                            poids: response.data[0].colisHistory[index].poids,
                            id_colis: response.data[0].colisHistory[index].id_colis,
                        });
                    }
            }
            setRenvoisColisData(data);
        }
        if (response) setDocument(FormatDocument(response));
    };
    const renvoiColis = async () => {
        const response = await POST(API.DOCUMENT_RENVOI, renvoisColisData);
        dispatch({ status: response.status, message: response.message });
        if (!status) return;
        GetDocument();
    };

    useEffect(() => {
        GetDocument();
    }, []);

    const onSubmit = async (data: Options) => {
        const body = {
            fk_etat: +data.value,
            fk_document: +id,
        };
        const { status, message } = await POST(API.DOCUMENTS_STATUS, body);
        if (!status) return dispatch({ status, message });
        dispatch({ message, status });
        GetDocument();
        setShowSelect(false);
    };

    return (
        <section className="container mx-auto my-5 px-2 grid grid-cols-1 lg:grid-cols-6 gap-5">
            <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info={'Ajouté le ' + document.createAt}>
                {'Document ' + document.documentNumber}
            </Card>
            <Card className="lg:col-span-2 lg:col-start-3" icon={FaWarehouse} info={'En provenance de'}>
                <Link to={Routes.COMPANY + '/' + document.tiersSourceId} className="text-purple-900 hover:underline">
                    {document.tiersSourceName}
                </Link>
            </Card>
            <Card className="lg:col-span-2 lg:col-start-5 " icon={FaFlagCheckered} info={'À destination de'}>
                <Link to={Routes.COMPANY + '/' + document.tiersDestId} className="text-purple-900 hover:underline">
                    {document.tiersDestName}
                </Link>
            </Card>
            <div className="lg:col-start-1 lg:col-span-4 lg:row-start-2 row-span-6 flex flex-col">
                <p>Historique de document</p>
                <Table columns={colisColumns} data={document.documentHistories} />
                {renvoisColisData && (
                    <Button type="button" className="mt-5" onClick={() => renvoiColis()} bold>
                        <p>Renvoyer les colis KO</p>
                    </Button>
                )}
            </div>
            <div className="lg:col-start-5 lg:col-span-2 lg:row-start-2 flex flex-col">
                <p>Liste des colis</p>
                <Table columns={HistoryColumn} data={document.packageHistories} />{' '}
            </div>
            <div className="border border-purple-400 py-5 lg:col-start-5 lg:col-span-2 lg:row-start-3 flex items-center p-5">
                <span className="flex flex-col">
                    <p>{document.tiersDestName}</p>
                    <p className="text-sm text-gray-600">{document.tiersDestAddress}</p>
                </span>
            </div>
            <div className="py-5 lg:row-start-4 lg:col-start-5 lg:col-span-2">
                <Notification message={state.message} status={state.status} />
                {showSelect && (
                    <>
                        <Select
                            name="etat"
                            option={statusOption}
                            onChange={(e: Options) => setStatus(e)}
                            className="py-2"
                            isMulti={false}
                        />
                        <Button
                            type="submit"
                            className="w-full flex items-center justify-center"
                            variant="secondary"
                            onClick={() => onSubmit(status)}
                            bold
                        >
                            Appliquer le changement ainsi qu&apos;aux colis
                        </Button>
                    </>
                )}
                <Button
                    type="button"
                    className="w-full flex items-center justify-center mt-5"
                    onClick={() => setShowSelect(!showSelect)}
                    bold
                >
                    {showSelect ? <p>Ne pas changer l&apos;état</p> : <p>Changer l&apos;état</p>}
                </Button>
            </div>
        </section>
    );
};

export default Document;

const CustomCell = (props: {
    value: string;
    row: { original: { color: 'success' | 'secondary' | 'info' | 'warning' | 'none' } };
}) => (
    <Fragment>
        <TextDeco text={props.value} color={props.row.original.color} />
    </Fragment>
);

const LinkInCell = (props: { value: string; row: { original: { delivery_id: string } } }) => (
    <Fragment>
        <Link to={`${Routes.DELIVERY}/${props.row.original.delivery_id}`}>
            <span className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</span>
        </Link>
    </Fragment>
);

const PackageCell = (props: { value: string; row: { original: { id: string; weights: string } } }) => (
    <Fragment>
        <Link to={`${Routes.PACKAGES}/${props.row.original.id}`}>
            <span className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</span>
        </Link>
        <p className="text-sm">{props.row.original.weights}</p>
    </Fragment>
);

const colisColumns: Column<DocumentHistories>[] = [
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'État',
        accessor: 'label',
        Cell: CustomCell,
    },
    {
        Header: 'Livraison',
        accessor: 'delivery_id',
        Cell: LinkInCell,
    },
    {
        Header: 'Livreur',
        accessor: 'deliveryMan_name',
    },
];

const HistoryColumn: Column<PackagesHistories>[] = [
    {
        Header: 'Colis',
        accessor: 'number',
        Cell: PackageCell,
    },
    {
        Header: 'État',
        accessor: 'label',
        Cell: LocationCell,
    },
];
