import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import CompanyForm from './CompanyForm';
import CompanyStats from './CompanyStats';
import DestinatairesSources from './DestinationsSources';
import Button from 'components/atoms/Buttons';

import { GET } from 'utils/Fetch';
import API from 'constants/API';
import Company, { FormatCompany, CompanyBuilder } from 'models/CompanyBuilder/Company';
import Loading from 'components/organisms/Modal/Loading';

const TierProfile = () => {
    const { id = '' } = useParams<{ id: string }>();
    const [loading, setLoading] = useState<boolean>(true);
    const [selectView, setSelectView] = useState<string>('info');
    const [tiersInfo, setTiersInfo] = useState<Company>(CompanyBuilder());

    const GetTier = async () => {
        const { status, ...response } = await GET(`${API.TIER}/${id}`);
        if (!status) return;
        const Tiers = FormatCompany(response);

        setLoading(() => {
            setTiersInfo(Tiers);
            return false;
        });
    };

    useEffect(() => {
        if (id !== '0') {
            GetTier();
        } else {
            setLoading(false);
            /*  setSelectView(false); */
        }
    }, [id]);

    const tiersIsDistributor = () => {
        if (tiersInfo.typeId === '1') return 1;
        if (tiersInfo.typeId === '2') return 0;
        return 0;
    };

    function view() {
        if (selectView === 'stat') return <CompanyStats id={id} isDistributor={tiersIsDistributor()} />;
        if (selectView === 'info') return <CompanyForm id={id} tiersInfo={tiersInfo} />;
        if (selectView === 'sourcesDestinations') return <DestinatairesSources id={id} typeId={tiersInfo.typeId} />;
    }

    if (loading) return <Loading />;
    return (
        <main className="container flex flex-col items-center mx-auto py-2">
            <section className="w-2/5 flex justify-between">
                <Button bold active={selectView === 'info'} onClick={() => setSelectView('info')}>
                    Information
                </Button>
                <Button
                    bold
                    active={selectView === 'sourcesDestinations'}
                    onClick={() => setSelectView('sourcesDestinations')}
                >
                    {tiersInfo.typeId === '1' ? 'Pharmacie' : 'Grossiste-Repartiteur/Centre-Logistique'}
                </Button>
                {tiersInfo.typeId === '1' && id !== '0' && (
                    <>
                        <Button bold active={selectView === 'stat'} onClick={() => setSelectView('stat')}>
                            Statistique
                        </Button>
                    </>
                )}
            </section>
            {view()}
        </main>
    );
};

export default TierProfile;
